@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

* {
    scroll-behavior: smooth !important;
}

body {
    font-family: 'Source Sans Pro';
}

html {
    @apply text-jet; 
}

@layer components{
    .nav{
        @apply px-6 hover:text-deepOrange ease-linear transition-all duration-150
    }

    .btn{
        @apply text-lg  laptop:text-lg  border-0 w-[300px] h-[44px] rounded-full shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150;
    }

    .footerText{
        @apply text-center text-white text-lg py-[15px] laptop:text-left desktop:text-left hover:text-deepOrange ease-linear transition-all duration-150;
    }

    .footerIcon{
        @apply text-2xl hover:opacity-[80%] text-white inline-block mx-3 hover:text-deepOrange ease-linear transition-all duration-150;
    }
}